export const environment = {
  production: true,
  development: false,
  staging: false,
  version: 'v1',

  //noblaa
  basicUrl: 'https://gateway.nobala.edu.sa/api',
  parentUrl: 'https://parent.nobala.edu.sa/',
  teacherUrl: 'https://teacher_portal.nobala.edu.sa/',
  studentUrl: 'https://student_portal.nobala.edu.sa/',
  dmUrl: 'https://dm.nobala.edu.sa/',

  //ajial
  //basicUrl: 'https://gateway-ajial.abnaey.com/api',
  //parentUrl: 'https://parent-ajial.abnaey.com/',
  //teacherUrl: 'https://teacher_portal-ajial.abnaey.com/',
  //studentUrl: 'https://student_portal-ajial.abnaey.com/',
  //dmUrl: 'https://dm-ajial.abnaey.com/',
  
  mapsApiKey: 'AIzaSyDZsQEDaOUzZC69BShQQUTW_x_dVVHXO8Y',
};
