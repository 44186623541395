import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "roles",
    loadChildren: () => import("../../components/roles/roles.module").then((m) => m.RolesModule),
  },
  {
    path: "questionnaires",
    loadChildren: () => import("../../components/questionnaires/questionnaires.module").then((m) => m.QuestionnairesModule),
  },
  {
    path: "suggestions",
    loadChildren: () => import("../../components/suggestions/suggestions.module").then((m) => m.SuggestionsModule),
  },
  {
    path: "users",
    loadChildren: () => import("../../components/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "notifications",
    loadChildren: () => import("../../components/notificationsService/notifications-service.module").then((m) => m.NotificationsModule),
  },
  {
    path: "settings",
    loadChildren: () => import("../../components/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "HR",
    loadChildren: () => import("../../components/hr/hr.module").then((m) => m.HRModule),
  },
  {
    path: "billing",
    loadChildren: () => import("../../components/billing/billing.module").then((m) => m.BillingModule),
  },
  {
    path: "ticketing",
    loadChildren: () => import("../../components/ticketing/ticketing.module").then((m) => m.TicketingModule),
  },
  {
    path: "employees-requests",
    loadChildren: () => import("../../components/employees-requests/employees-requests.module").then((m) => m.EmployeesRequestsModule),
  },
  {
    path: "buildings",
    loadChildren: () => import("../../components/buildings/buildings.module").then((m) => m.BuildingsModule),
  },
  {
    path: "academic-years",
    loadChildren: () => import("../../components/academic-years/academic-years.module").then((m) => m.AcademicYearsModule),
  },
  {
    path: "educational-settings",
    loadChildren: () => import("../../components/educational-settings/educational-settings.module").then((m) => m.EducationalSettingsModule),
  },
  {
    path: "appointments",
    loadChildren: () => import("../../components/appointment-service/appointment-service.module").then((m) => m.AppointmentServiceModule),
  },
  {
    path: "transportation",
    loadChildren: () => import("../../components/transportation/transportation.module").then((m) => m.TransportationModule),
  },
  {
    path: "maintenance",
    loadChildren: () => import("../../components/maintenance/maintenance.module").then((m) => m.MaintenanceModule),
  },
  {
    path: "prices",
    loadChildren: () => import("../../components/prices-list/prices-list.module").then((m) => m.PricesListModule),
  },
  {
    path: "enrollment",
    loadChildren: () => import("../../components/enrollment-requests/enrollment-requests.module").then((m) => m.EnrollmentRequestsModule),
  },
  {
    path: "withdrawal",
    loadChildren: () => import("../../components/withdrawal-requests/withdrawal-requests.module").then((m) => m.WithdrawalRequestsModule),
  },
  {
    path: "transformation",
    loadChildren: () => import("../../components/transfering-school/transfer.module").then((m) => m.TransferModule),
  },
  {
    path: "upgrade",
    loadChildren: () => import("../../components/upgrade-requests/upgrade-requests.module").then((m) => m.UpgradeRequestsModule),
  },
  {
    path: "exception",
    loadChildren: () => import("../../components/exception-requests/exception-requests.module").then((m) => m.ExceptionRequestsModule),
  },
  {
    path: "classes",
    loadChildren: () => import("../../components/classes/classes.module").then((m) => m.ClassesModule),
  },
  // {
  //   path: "bills",
  //   loadChildren: () => import("../../components/bills/bills.module").then((m) => m.BillsModule),
  // },
  // {
  //   path: "bills-tracking",
  //   loadChildren: () => import("../../components/bills-tracking/bills-tracking.module").then((m) => m.BillsTrackingModule),
  // },
  {
    path: "finance",
    loadChildren: () => import("../../components/finance/finance.module").then((m) => m.FinanceModule),
  },
  {
    path: "resend-sanad",
    loadChildren: () => import("../../components/sanad-resend/sanad-resend.module").then((m) => m.ResendSanadModule),
  },
  // {
  //   path: "banks",
  //   loadChildren: () => import("../../components/banks/banks.module").then((m) => m.BanksModule),
  // },
  {
    path: "students",
    loadChildren: () => import("../../components/students/students.module").then((m) => m.StudentsModule),
  },
  {
    path: "supply-chain",
    loadChildren: () => import("../../components/supply-chain/supply-chain.module").then((m) => m.SupplyChainModule),
  },
  {
    path: "assets",
    loadChildren: () => import("../../components/assets-group/assets-group.module").then((m) => m.AssetsGroupModule),
  },
  {
    path: "indebtedness",
    loadChildren: () => import("../../components/indebtedness/indebtedness.module").then((m) => m.IndebtednessModule),
  },
  {
    path: "points",
    loadChildren: () => import("../../components/points/points.module").then((m) => m.PointsModule),
  },
  {
    path: "debts",
    loadChildren: () => import("../../components/debts/debts.module").then((m) => m.DebtsModule),
  },
  {
    path: "componey-managment",
    loadChildren: () => import("../../components/componey-managment/componey-managment.module").then((m) => m.ComponeyManagmentModule),
  },
  {
    path: "clinics",
    loadChildren: () => import("../../components/clinics/clinics.module").then((m) => m.ClinicsModule),
  },
  {
    path: "reports",
    loadChildren: () => import("../../components/Monitor/monitor.module").then((m) => m.MonitorModule),
  },

  //Manager
  {
    path: "manager",
    loadChildren: () => import("../../components/manager/manager.module").then((m) => m.ManagerModule),
  },
];
