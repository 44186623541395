import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class RequestHeadersInterceptor implements HttpInterceptor {

  constructor(private authService: LoginService, private toastr: ToastrService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.authService.isTokenExpired()) {
      const token = JSON.parse(localStorage.getItem('user')!).access_token
      // const token = 'hfagdsf'
      request = request.clone({
        headers:
          request.headers
            .append('Accept', 'application/json')
            .append('Authorization', localStorage.getItem('user') ? `Bearer ${token}` : '')
            // .append('Content-Type', 'false')
            // .append('Service-Secret', 'service_001')
            // .append("mimeType", "multipart/form-data")
            // .append("contentType", 'false')
            // .append("processData", 'false')
            .append('Accept-Language', localStorage.getItem('currentLang') ? localStorage.getItem('currentLang')! : 'ar')
        // .append('Content-Type', 'multipart/form-data')
      },)
    } else {
      this.authService.Logout()
    }

    return next.handle(request).pipe(
      catchError((error) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side error
          switch (error.status) {
            case 400:
              errorMessage = 'Bad Request';
              break;
            case 401:
              errorMessage = 'Unauthorized: Redirecting to login';
              this.router.navigate(['/auth/login']);
              // Redirect to login or show a modal, etc.
              break;
            case 403:
              errorMessage = 'Forbidden';
              break;
            case 404:
              errorMessage = 'Not Found';
              break;
            case 422:
              errorMessage = error
              break;
            case 500:
              errorMessage = 'Internal Server Error';
              break;
            default:
              errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
          }
        }

        console.error(errorMessage);
        return throwError(errorMessage);
      })
    );
  }
}
